import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DocumentLayout from "../layouts/Document";
import { Indent } from "../components/DocumentParts";
import imagePN from "../images/pn.png";
import { Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <DocumentLayout title="Terms of Use">{children}</DocumentLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`FaintLines, Inc. Terms of Use`}</h1>
    <p>{`These Terms of Use (this "`}<strong parentName="p">{`Agreement`}</strong>{`") apply to all users of
FaintLines, Inc.'s ("`}<strong parentName="p">{`us`}</strong>{`", "`}<strong parentName="p">{`our`}</strong>{`", and "`}<strong parentName="p">{`we`}</strong>{`") online and
real-world immersive experiences, including mobile applications and
online services and websites ("`}<strong parentName="p">{`Apps`}</strong>{`"), and real-world immersive
experience platform ("`}<strong parentName="p">{`Platform`}</strong>{`"). This Agreement also governs your
use of our websites, including but not limited to `}<a parentName="p" {...{
        "href": "https://faintlines.com"
      }}>{`faintlines.com`}</a>{`, `}<a parentName="p" {...{
        "href": "https://adventurestore.me"
      }}>{`adventurestore.me`}</a>{`, `}<a parentName="p" {...{
        "href": "https://peekaph.one"
      }}>{`peekaph.one`}</a>{`, `}<a parentName="p" {...{
        "href": "https://peekaphonegame.com"
      }}>{`peekaphonegame.com`}</a>{`
("`}<strong parentName="p">{`Sites`}</strong>{`"), as well as any of our social media channels, or any
online platforms, features, products, services and/or programs that are
part of the Apps, Platform, or Sites (all of the foregoing,
collectively, the "`}<strong parentName="p">{`Services`}</strong>{`").`}</p>
    <p>{`This Agreement includes important information about your legal rights,
remedies, and obligations, including various limitations and exclusions,
and provisions that govern the procedure, jurisdiction and venue of any
disputes. Some exceptions to the Agreement may apply based on your
country of residence.`}</p>
    <p>{`THIS AGREEMENT SETS FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT
GOVERN YOUR USE OF THE SERVICES. BY ACCESSING OR USING THE SERVICES, YOU
ARE ACCEPTING THIS AGREEMENT (ON BEHALF OF YOURSELF OR THE ENTITY THAT
YOU REPRESENT). YOU REPRESENT THAT YOU ARE OF SUFFICIENT LEGAL AGE IN
YOUR JURISDICTION OR RESIDENCE TO USE OR ACCESS THE SERVICES AND TO
ENTER INTO THIS AGREEMENT. IF YOU DO NOT AGREE WITH ALL OF THE
PROVISIONS OF THIS AGREEMENT, DO NOT ACCESS AND/OR USE THE SERVICES.`}</p>
    <p>{`FaintLines, Inc. may modify this Agreement at any time, and if we do, we
will notify you by posting the modified Agreement on the Site and in the
App. It's important that you review any modified Agreement before you
continue using the Services. If you continue to use the Services, you
are bound by the modified Agreement. If you do not agree to be bound by
the modified Agreement, then do not use the Services.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Adventures`}</strong>{`. Our Services are designed to enable you to interact
in shared game worlds blended with information from the real world,
including real websites and social media platforms, with fictional
features and characters (each, an "`}<strong parentName="p">{`Adventure`}</strong>{`"). The storyline of
our Adventures may be personalized or tailored to you. For example,
names of characters and locations may be changed based on the
location you are in. Our Adventures may also allow you to interact
with other users or actors so you can play together or communicate
using chat features through or outside of the Services.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Privacy`}</strong>{`. To provide the Services, we need information about you.
Please refer to our `}<Link to="/privacy" mdxType="Link">{`Privacy Policy`}</Link>{` to help you understand
what information we collect, how we use it, and what choices you
have when you use our Services.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Security`}</strong>{`. While we provide reasonable precautions to protect
your personal information, we do not and cannot guarantee or warrant
that information transmitted through the Internet is secure, or that
such transmissions are free from delay, interruption, interception
or error.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Accounts.`}</strong></p>
        <p parentName="li">{`4.1. `}<em parentName="p">{`Account Creation`}</em>{`. In order to use certain features of the
Services, you will need to register for an account
("`}<strong parentName="p">{`Account`}</strong>{`") and provide certain information about yourself,
including your name, e-mail address and a password
("`}<strong parentName="p">{`Registration Data`}</strong>{`"). When you create an Account, you
represent and warrant that: (a) all required information you
submit to us is truthful and accurate; and (b) you will maintain
the accuracy of such information. You may delete your Account at
any time, for any reason, by following the instructions on the
Sites or Apps. We may suspend or terminate your Account in
accordance with Section 12. We may use and share your
Registration Data in order to provide you with our Services and
as described in our Privacy Policy. Our use of your Registration
Data and certain other information about you are governed by our
Privacy Policy.`}</p>
        <p parentName="li">{`4.2. `}<em parentName="p">{`Responsibilities`}</em>{`. You are responsible for paying all costs
related to the transmission of data required to use the Services
(downloading, installing, launching, and using). You agree that
we shall not be liable for fees charged by third parties,
including but not limited to the cost or fees related to text
messages and voice or video calling charged by telephone
providers, internet service providers, or mobile device
operators. You also agree that we are also not responsible for
any cost of travel to Adventure locations or in connection with
the Services. You also acknowledge that you are responsible for
compliance with all applicable agreements, terms of use/service
and other policies of your telephone providers, internet service
providers, or mobile device operators. If you create an Account:
you are responsible for maintaining the confidentiality of your
Account login information and Registration Data; you are fully
responsible for all activities that occur under your Account;
and you agree to immediately notify us of any actual or
suspected unauthorized use of your Account or any other breach
of security. Neither we nor our indemnitees shall be responsible
for verifying your Registration Data. You will be solely
responsible for maintaining the confidentiality of your
Registration Data. We cannot and will not be liable for any loss
or damage arising from your failure to comply with the above
requirements.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Access to Services.`}</strong></p>
        <p parentName="li">{`5.1. `}<em parentName="p">{`License`}</em>{`. Subject to this Agreement, FaintLines, Inc. grants
you a personal, noncommercial, nonexclusive, nontransferable,
nonsublicensable, revocable, limited license to download, view,
display, and use the Services and Content therein solely for the
permitted use within the Services. "`}<strong parentName="p">{`Content`}</strong>{`" means the text,
software, scripts, graphics, photos, sounds, music, videos,
audiovisual combinations, communications, interactive features,
works of authorship of any kind, and information or other
materials that are generated, provided, or otherwise made
available through the Services. Content includes User Content
(defined below).`}</p>
        <p parentName="li">{`5.2. `}<em parentName="p">{`Certain Restrictions`}</em>{`. The rights granted to you in this
Agreement are subject to the following restrictions: (a) you
shall not license, sell, rent, lease, transfer, assign, host, or
otherwise commercially exploit the Services or Content, whether
in whole or in part, or any content displayed on the
Services; (b) you shall not modify, make derivative works of,
disassemble, reverse compile or reverse engineer any part of the
Services; (c) you shall not access the Services in order to
build a similar or competitive website, application, product, or
service; (d) except as expressly stated herein, no part of the
Services or Content may be copied, reproduced, distributed,
republished, downloaded, displayed, posted or transmitted in any
form or by any means; and, (e) you shall abide by the Acceptable
Use Policy (defined in Section 8). Unless otherwise indicated,
any future release, update, or other addition to functionality
of the Services shall be subject to this Agreement. All
copyright and other proprietary notices on the Services (or on
any Content displayed on the Services) must be retained on all
copies thereof.`}</p>
        <p parentName="li">{`5.3. `}<em parentName="p">{`Modification`}</em>{`. The Services are provided to you as a
convenience and for your information only. We reserve the right,
at any time, to modify, suspend, or discontinue the Services (in
whole or in part) with or without notice to you at our sole
discretion and will, at that time, provide you the opportunity
to terminate your paid subscription, if any. You agree that
FaintLines, Inc. will not be liable to you or to any third party
for any modification, suspension, or discontinuation of the
Services or any part thereof.`}</p>
        <p parentName="li">{`5.4. `}<em parentName="p">{`No Support or Maintenance`}</em>{`. You acknowledge and agree that
FaintLines, Inc. will have no obligation to provide you with any
support or maintenance in connection with the Services.`}</p>
        <p parentName="li">{`5.5. `}<em parentName="p">{`Ownership`}</em>{`. Excluding any User Content (defined below) that you
may provide and copyrights, patents, trademarks, and trade
secrets held and protected by a third party, you acknowledge
that all the intellectual property rights, including copyrights,
patents, trademarks, and trade secrets, in the Services and its
contents are owned by FaintLines, Inc. and/or its licensors.
Neither this Agreement, nor your access to the Services,
transfers to you or any third party any rights, title or
interest in or to such intellectual property rights, except for
the limited access rights expressly set forth in this Section 5.
FaintLines, Inc. and its affiliates and service providers
reserve all rights not expressly granted in this Agreement.
There are no implied licenses granted under this Agreement.`}</p>
        <p parentName="li">{`5.6.`}<em parentName="p">{`Open Source`}</em>{`. Certain items of independent, third-party code
may be included in the Services that are subject to the GNU
General Public License ("`}<strong parentName="p">{`GPL`}</strong>{`") or other open-source licenses
("`}<strong parentName="p">{`Open-Source Software`}</strong>{`"). The Open-Source Software is
licensed under the terms of the license that accompanies such
Open-Source Software. Nothing in this Agreement limits your
rights under, or grants you rights that supersede, the terms and
conditions of any applicable end-user license for such
Open-Source Software. In particular, nothing in this Agreement
restricts your right to copy, modify and distribute such
Open-Source Software that is subject to the terms of the GPL.`}</p>
        <p parentName="li">{`5.7. `}<em parentName="p">{`Consent to receive telephone calls and text messages from
FaintLines, Inc. and Actors.`}</em></p>
        <Indent mdxType="Indent">
    5.7.1. <u>Opt-in</u>. If you elect to receive automated transactional
    text messages and/or calls from FaintLines, Inc., these messages will be
    sent to the telephone number provided during the time of your opt-in.
    One of the Services' and actor's primary methods of communication with
    you about and during Adventures and interaction with the Services is by
    text message or telephone call. Text message and call frequency varies
    based on your use of the Services and interaction with FaintLines, Inc.
    and the Services. Message and data rates may apply.
        </Indent>
        <Indent mdxType="Indent">
    5.7.2. <u>Opt-Out and Assistance</u>. To opt-out of any future text
    messages or telephone calls, reply "STOP" to stop receiving text
    messages from FaintLines, Inc. and others texting on our behalf,
    including characters or actors in an Adventure. For further assistance,
    reply to any text message from us with the word "HELP", email us at
    support@faintlines.com, or ask the caller for assistance. You may
    receive an additional mobile message confirming your decision to opt
    out. You understand and agree that the foregoing options are the only
    reasonable methods of opting out. You understand and agree that once you
    exercise your legal right to opt-out of receiving transactional text
    messages, you will no longer be eligible to participate in Adventures
    and your access and use of the Services may be subject to termination
    because FaintLines, Inc. and actors can no longer provide you an
    essential function of the Service, which is communicating with you
    during and about the Services.
        </Indent>
        <Indent mdxType="Indent">
    5.7.3. <u>Update Mobile Telephone Number</u>. You agree to provide
    FaintLines, Inc. with a valid mobile telephone number. You agree to
    promptly update your mobile telephone number upon any change of your
    mobile telephone number and to opt-out of receiving text message
    communications using your previous phone number before changing your
    telephone number applicable to the Services. To the extent permitted by
    applicable law, you agree that FaintLines, Inc. will not be liable for
    failed, delayed, or misdirected delivery of any information sent through
    the text message program or telephone call, any errors in such
    information, and/or any action you may or may not take in reliance on
    the content of the text messages or calls.
        </Indent>
        <p parentName="li">{`5.8. `}<em parentName="p">{`Limitation of Services`}</em></p>
        <Indent mdxType="Indent">
    5.8.1. <u>Intended Use of Services.</u> The Services are intended to be
    accessed and used for non-time-critical purposes. While we aim for the
    Services to be highly reliable and available, they are not intended to
    be reliable or available 100% of the time. The Services are subject to
    sporadic interruptions and failures for a variety of reasons beyond
    FaintLines, Inc.'s control, including Wi-Fi intermittency, service
    provider uptime, mobile notifications and operators, among others. You
    acknowledge these limitations and agree that FaintLines, Inc. is not
    responsible for any damages allegedly caused by the failure or delay of
    the Services.
        </Indent>
        <Indent mdxType="Indent">
    5.8.2. <u>Reliability of Services</u>. You acknowledge that the Services
    are not error-free or 100% reliable and 100% available. Proper
    functioning of the Services relies and is dependent on, among other
    things, the transmission of data through Wi-Fi networks, enabled
    wireless device (such as a phone or tablet) and broadband internet
    access, or cellular service, for which neither FaintLines, Inc. nor any
    wireless or data carrier is responsible, and may be interrupted,
    delayed, refused, or otherwise limited for a variety of reasons,
    including insufficient coverage, power outages, termination of service
    and access, environmental conditions, interference, non-payment of
    applicable fees and charges, system capacity, upgrades, repairs or
    relocations, and priority access by emergency responders in the event of
    a disaster or emergency (collectively, "<b>Service Interruptions</b>").
    You understand that Service Interruptions may result in the Services
    being unreliable or unavailable for the duration of the Service
    Interruption.
        </Indent>
        <Indent mdxType="Indent">
    5.8.3. <u>Service Interruptions; no refund or rebate</u>. The Services
    may be suspended temporarily, without notice, for security reasons,
    systems failure, maintenance and repair, or other circumstances. You
    agree that you will not be entitled to any refund or rebate for such
    suspensions. FaintLines, Inc. does not offer any specific uptime
    guarantee for the Services.
        </Indent>
        <Indent mdxType="Indent">
    5.8.4. <u>Outages</u>. In the event of any Service Interruptions to the
    Wi-Fi network or internet service with which you are connected, the
    Services may be unreliable or unavailable for the duration of the
    Service Interruption. Further, features and functionalities of the
    Services may be unavailable on you for the duration of the Service
    Interruption.
        </Indent>
        <p parentName="li">{`5.9. `}<em parentName="p">{`App Stores`}</em>{`. You acknowledge and agree that the availability of
the Services may be dependent on the third-party websites from
which you download the Apps, e.g., the Google Play Store from
Google or the App Store from Apple (each an "`}<strong parentName="p">{`App Store`}</strong>{`").
You acknowledge that this Agreement is between you and
FaintLines, Inc. and not with an App Store. Each App Store may
have its own terms and conditions to which you must agree before
downloading Apps from it. You agree to comply with such App
Store terms and conditions, and your license to use the Services
is conditioned upon your compliance with such App Store terms
and conditions. To the extent that such other terms and
conditions from such App Store are less restrictive than or
otherwise conflict with the terms and conditions of this
Agreement, the more restrictive or conflicting terms and
conditions in this Agreement apply.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`User Content.`}</strong></p>
        <p parentName="li">{`6.1. `}<em parentName="p">{`User Content`}</em>{`. "`}<strong parentName="p">{`User Content`}</strong>{`" means any and all
information, data, and Content that a user submits to, creates,
or uses with, the Services. You are solely responsible for your
User Content. You assume all risks associated with use of your
User Content, including any reliance on its accuracy,
completeness or usefulness by others, or any disclosure of your
User Content that personally identifies you or any third party.
You hereby represent and warrant that your User Content does not
violate our `}<strong parentName="p">{`Acceptable Use Policy`}</strong>{` (defined in Section 8).
You may not represent or imply to others that your User Content
is in any way provided, sponsored, or endorsed by FaintLines,
Inc. `}<strong parentName="p">{`Please be aware that information provided in the User
Content may be publicly visible`}</strong>{`. Because you alone are
responsible for your User Content, you may expose yourself to
liability if, for example, your User Content violates the
Acceptable Use Policy. We are not obligated to backup any User
Content, and your User Content may be deleted at any time
without prior notice. You are solely responsible for creating
and maintaining your own backup copies of your User Content if
you desire. You are also responsible for any actions you take in
reliance on User Content of other users. You acknowledge and
agree that we are not responsible for any User Content, whether
provided by you or by others. We make no guarantees regarding
the accuracy, currency, suitability, or quality of any User
Content.`}</p>
        <p parentName="li">{`6.2. `}<em parentName="p">{`License`}</em>{`. You hereby grant to FaintLines, Inc. an irrevocable,
nonexclusive, royalty-free and fully paid, worldwide license to
reproduce, distribute, publicly display and perform, prepare
derivative works of, incorporate into other works, and otherwise
use and exploit your User Content, and to grant sublicenses of
the foregoing rights, solely for the purposes of researching or
developing new products and/or services and including your User
Content in the Services. You hereby irrevocably waive (and agree
to cause to be waived) any claims and assertions of moral rights
or attribution with respect to your User Content. By posting
User Content, you attest that you are not restricted or
prohibited from making such content publicly available under law
or contractual or fiduciary relationships.`}</p>
        <p parentName="li">{`6.3. `}<em parentName="p">{`Recordings and Use of Likeness`}</em>{`. While participating in an
Adventure, the Services may allow you to make recordings of your
voice. In order to use this feature, you will need to enable
your microphone on your device. We may use recordings of your
image, likeness, name, dialogue, biographic information,
personal characteristics, and voice during the use of our
Services (please see our `}<u>{`Privacy Policy`}</u>{` for details on
how we use your personal data), and transcribe data, in
connection with the Adventures and to otherwise enhance the
Services. You hereby grant FaintLines, Inc. a perpetual,
irrevocable, royalty free right to use your User Content in
connection with the Services, as we deem fit and as permitted by
law. We may use User Content in promotional and marketing
materials and on social media in accordance with this Agreement.`}</p>
        <p parentName="li">{`6.4. `}<em parentName="p">{`No Third Party Consents`}</em>{`. You are responsible for ensuring that
there is nothing unintentionally included in the recordings or
other User Content that is subject to rights of any third party,
including but not limited to any background noises or voices. We
will have no liability to you, or anyone else, for a claim that
any recording or User Content that you submit includes data that
you are not permitted to share, and you agree to indemnify us
for any liability that we may incur based on your User Content.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Third Party Websites or Resources; Other Users.`}</strong></p>
        <p parentName="li">{`7.1. `}<em parentName="p">{`Third-Party Resources`}</em>{`. Our Services may contain links or
directions to navigate to third party websites, applications,
advertisements, or resources (collectively, "`}<strong parentName="p">{`Third-Party
Resources`}</strong>{`"). FaintLines, Inc. may set up accounts on certain
Third-Party Resources for you to interact with during an
Adventure provided by our Services. We are not responsible for
services on or available from those Third-Party Resources, or
links displayed on such Third-Party Resources. When you use any
Third-Party Resource as part of an Adventure or our Services,
the applicable Third-Party Resources' terms and policies apply,
including the third party's privacy and data gathering
practices. To the extent permitted under applicable law, you
acknowledge sole responsibility for and assume all risk arising
from, your use of any Third-Party Resources.`}</p>
        <p parentName="li">{`7.2. `}<em parentName="p">{`Third-Party Services`}</em>{`. FaintLines, Inc. is not responsible for
the availability or quality of third party services, including
cell phone networks, hotspots, wireless internet and other
services ("`}<strong parentName="p">{`Third-Party Services`}</strong>{`"). Such Third-Party Services
may affect your ability to utilize the Services and you hereby
waive and release FaintLines, Inc. and any other party involved
in creating or delivering the Services from all claims, demands,
causes of action, damages, losses, expenses or liability which
may arise out of, result from, or relate in any way to such
Third-Party Services.`}</p>
        <p parentName="li">{`7.3. `}<em parentName="p">{`Other Users`}</em>{`. Your interactions with other users of the
Services, Adventure participants, and/or Adventure characters
and actors ("`}<strong parentName="p">{`Other Users`}</strong>{`") are solely between you and such
Other Users, whether such interactions occur while using our
Services or outside of our Services. You agree that FaintLines,
Inc. will not be responsible for any loss, injury, or damage
incurred as the result of any such interactions. We have no
obligation to become involved or to assist in resolving any
dispute between you and any Other Users. Please be mindful of
the information you provide in public interactions on the
Services or when interacting with characters in the Adventures.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Acceptable Use Policy`}</strong>{`. The following terms constitute our
"`}<strong parentName="p">{`Acceptable Use Policy`}</strong>{`":`}</p>
        <p parentName="li">{`8.1. `}<em parentName="p">{`User Content`}</em>{`. You agree not to use the Services to create,
collect, upload, transmit, display, or distribute any User
Content (i) that violates any third-party right, including any
copyright, trademark, patent, trade secret, moral right, privacy
right, right of publicity, or any other intellectual property or
proprietary right; (ii) that is unlawful, harassing, abusive,
tortious, threatening, harmful, invasive of another's privacy,
vulgar, defamatory, false, intentionally misleading, trade
libellous, pornographic, obscene, patently offensive, promotes
racism, bigotry, hatred, or physical harm of any kind against
any group or individual or is otherwise objectionable; (iii)
that is harmful to minors in any way; or (iv) that is in
violation of any law, regulation, or obligations or restrictions
imposed by any third party.`}</p>
        <p parentName="li">{`8.2. `}<em parentName="p">{`Safe and Appropriate Use`}</em>{`. While you are using our Services,
please be aware of your surroundings, and play and communicate
safely. Some Adventures may take place in real locations, on
public or private property, and you are responsible for abiding
by any rules and regulations of such locations. We will not be
responsible for any harm you cause to yourself, any other
individuals or to any property while you are participating in,
or in connection with, an Adventure. You agree that your use of
the Services is at your own risk. You agree that you will not
use the Services to violate any applicable law, regulation, or
instructions as outlined in this Agreement, and you will not
encourage or enable any other individual to do so. By offering
the Adventures and other Services, we make no representation
that it is medically safe or healthy for you to participate, and
you must make your own judgment before participating in any
Adventure. If you have any concerns regarding the safety of an
Adventure, please consult with a healthcare professional before
participating in an Adventure or using our Services. The
Services and Adventures often blend fiction with reality. The
Services allow you to interact with actors as part of an
Adventure, and you may not be able to determine whether a
character or a user is communicating for himself or herself, or
whether such an individual is an actor. Please communicate
safely with all individual users.`}</p>
        <p parentName="li">{`8.3. `}<em parentName="p">{`Your Interactions with Other People`}</em>{`. You agree that when using
the Services, you will maintain safe and appropriate contact and
communication with other players, Service actors, and other
people in the real world. You will not harass, threaten or
otherwise violate the legal rights of others. You will not
trespass, or in any manner attempt to gain or gain access to any
property or location where you do not have a right or permission
to be, and will not otherwise engage in any activity that may
result in injury, death, sexual harassment, property damage,
nuisance, or liability of any kind. You hereby release
FaintLines, Inc. from any liability, claim, obligation, loss or
demand arising out of or in any way connected with any disputes
between you and any third party relating to your use of the
Services.`}</p>
        <p parentName="li">{`8.4. `}<em parentName="p">{`Conduct, General Prohibitions, and FaintLines, Inc.'s
Enforcement Rights`}</em>{`. You agree that you are responsible for your
own conduct and User Content while using the Services, and for
any consequences thereof. In addition, you agree not to do any
of the following, unless applicable law mandates that you be
given the right to do so:`}</p>
        <Indent mdxType="Indent">
    8.4.1. collect, store or share any personally identifiable information
    of other users from the Services without their express permission;
        </Indent>
        <Indent mdxType="Indent">
    8.4.2. extract, scrape, or index the Services or Content (including
    information about users or gameplay);
        </Indent>
        <Indent mdxType="Indent">
    8.4.3. use the Services or Content, or any portion thereof, for any
    commercial purpose or in a manner not permitted by this Agreement,
    including but not limited to (a) gathering in-App items or resources for
    sale outside the Apps, (b) performing services in the Apps in exchange
    for payment outside the Apps, or (c) selling, reselling, or renting the
    Apps or your Account;
        </Indent>
        <Indent mdxType="Indent">
    8.4.4. attempt to access or search the Services or Content or download
    Content from the Services through the use of any technology or means
    other than those provided by FaintLines, Inc. or other generally
    available third party web browsers (including without limitation
    automation software, bots, spiders, crawlers, data mining tools, or
    hacks, tools, agents, engines, or devices of any kind);
        </Indent>
        <Indent mdxType="Indent">
    8.4.5. attempt to decipher, decompile, disassemble, or reverse engineer
    any of the software used to provide the Services or Content;
        </Indent>
        <Indent mdxType="Indent">
    8.4.6. use the Services to harvest, collect, gather or assemble
    information or data regarding other users, including e-mail addresses,
    without their consent;
        </Indent>
        <Indent mdxType="Indent">
    8.4.7. bypass, remove, deactivate, descramble, or otherwise circumvent
    any technological measure implemented by FaintLines, Inc. or any of
    FaintLines, Inc.'s providers or any other third party (including another
    user) to protect the Services or Content;
        </Indent>
        <Indent mdxType="Indent">
    8.4.8. use, display, mirror, or frame the Services, Content, or any
    individual element within the Services or Content, FaintLines, Inc.'s
    name, any FaintLines, Inc. trademark, logo, or other proprietary
    information, or the layout and design of any page or App without
    FaintLines, Inc.'s express written consent;
        </Indent>
        <Indent mdxType="Indent">
    8.4.9. post, publish, submit or transmit any User Content that
    infringes, misappropriates, or violates a third party's patent,
    copyright, trademark, trade secret, moral rights, or other intellectual
    property rights, or rights of publicity or privacy;
        </Indent>
        <Indent mdxType="Indent">
    8.4.10. upload, transmit, or distribute to or through the Services any
    computer viruses, worms, or any software intended to damage or alter a
    computer system or data;
        </Indent>
        <Indent mdxType="Indent">
    8.4.11. access, tamper with, or use nonpublic areas of the Services,
    FaintLines, Inc.'s computer systems, or the technical delivery systems
    of FaintLines, Inc.'s providers;
        </Indent>
        <Indent mdxType="Indent">
    8.4.12. attempt to probe, scan, or test the vulnerability of any
    FaintLines, Inc. system or network or Service, or breach any security or
    authentication measures;
        </Indent>
        <Indent mdxType="Indent">
    8.4.13. interfere with, disrupt, or create an undue burden on servers or
    networks connected to the Services, or violate the regulations, policies
    or procedures of such networks;
        </Indent>
        <Indent mdxType="Indent">
    8.4.14. use any meta tags or other hidden text or metadata utilizing a
    FaintLines, Inc. trademark, logo, URL, or product name without
    FaintLines, Inc.'s express written consent;
        </Indent>
        <Indent mdxType="Indent">
    8.4.15. forge any TCP/IP packet header or any part of the header
    information in any e-mail or newsgroup posting, or in any way use the
    Services or Content to send altered, deceptive, or false source
    identifying information;
        </Indent>
        <Indent mdxType="Indent">
    8.4.16. interfere with, or attempt to interfere with, the access of any
    user, host, or network, including, without limitation, sending a virus,
    overloading, flooding, spamming, encrypting, or mailbombing the
    Services;
        </Indent>
        <Indent mdxType="Indent">
    8.4.17. send through the Services unsolicited or unauthorized
    advertising, promotional materials, junk mail, spam, chain letters,
    pyramid schemes, or any other form of duplicative or unsolicited
    messages, whether commercial or otherwise;
        </Indent>
        <Indent mdxType="Indent">
    8.4.18. delete, obscure, or in any manner alter any attribution,
    warning, or link that appears in the Services or the Content;
        </Indent>
        <Indent mdxType="Indent">
    8.4.19. violate any applicable law, rule, regulation or policy;or
        </Indent>
        <Indent mdxType="Indent">
    8.4.20. encourage or enable any other individual to do any of the
    foregoing.
        </Indent>
        <p parentName="li">{`8.5. `}<em parentName="p">{`Content Review`}</em>{`. Although FaintLines, Inc. is not obligated to
monitor access to or use of the Services or Content or to review
or edit any Content, we have the right to do so for the purpose
of operating the Services, to ensure compliance with this
Agreement, to comply with applicable law or other legal
requirements, or for any other lawful purpose. We reserve the
right to remove or disable access to any Content, at any time
and without notice. FaintLines, Inc. may remove any Content we
consider to be objectionable or in violation of this Agreement.
We have the right to investigate violations of this Agreement or
conduct that affects the Services. We may also consult and
cooperate with law enforcement authorities to prosecute users
and others who violate the law.`}</p>
        <p parentName="li">{`8.6. ANY ATTEMPT BY YOU TO DISRUPT OR INTERFERE WITH THE SERVICES,
INCLUDING WITHOUT LIMITATION UNDERMINING OR MANIPULATING THE
LEGITIMATE OPERATION OF ANY SITE OR APP, IS A BREACH OF THIS
AGREEMENT AND MAY BE A BREACH OR VIOLATION OF CRIMINAL AND CIVIL
LAWS.`}</p>
        <p parentName="li">{`8.7. `}<em parentName="p">{`Assumption of Risks`}</em>{`. Unless prohibited by applicable law, you
agree that by downloading the Apps or utilizing our Services,
you willingly, knowingly and voluntarily assume any and all
risks occurring before, during or after the use of the Service
and participation in an Adventure, including injury by any cause
and damage, loss, or theft of property. You acknowledge that
Adventures, and certain activities during Adventures, have
inherent and unforeseen risks, including but not limited to (a)
contact or collision with persons or objects, (b) obstacles
(e.g., natural and man-made water, road and surface
hazards), (c) equipment related hazards (e.g., broken, defective
or inadequate equipment, unexpected equipment failure), (d)
weather related hazards, (f) judgment and/or behavior related
problems by the general public (e.g., erratic or inappropriate
behavior or errors in judgment by individuals in public or
online), and (g) natural hazards. You agree to take reasonable
precautions before participating in an Adventure and its tasks
and activities. You further understand and acknowledge it is
your responsibility to use good judgement while participating in
Adventures. You acknowledge that it is your responsibility to
determine for yourself whether the Adventure locations and areas
are safe, adequate, and acceptable for participation. If you
believe or become aware of any unsafe conditions or unreasonable
risks, you agree to immediately cease participation in the
Adventure and notify FaintLines, Inc. at `}<a parentName="p" {...{
            "href": "mailto:support@faintlines.com"
          }}>{`support@faintlines.com`}</a>{`.`}</p>
        <p parentName="li">{`8.8. `}<em parentName="p">{`Enforcement`}</em>{`. We reserve the right (but have no obligation) to
review any User Content, and to investigate and/or take
appropriate action against you in our sole discretion if you
violate the Acceptable Use Policy or any other provision of this
Agreement or otherwise create liability for us or any other
person. Such action may include removing or modifying your User
Content, suspending or terminating your Account in accordance
with Section 14, and/or reporting you to law enforcement
authorities.`}</p>
        <p parentName="li">{`8.9. `}<em parentName="p">{`Feedback`}</em>{`. If you provide us with any feedback, ideas, or
suggestions (whether via e-mail, dedicated forms, or other
methods) regarding the Services, including any ideas or
suggestion related to proposed Adventures ("`}<strong parentName="p">{`Feedback`}</strong>{`"), you
hereby assign to us all rights in such Feedback and agree that
FaintLines, Inc. shall have the right to use (or not use) and
fully exploit such Feedback and related information in any
manner it deems appropriate, without notice or compensation to
you. You will not have any ownership rights in the Feedback.
FaintLines, Inc. will treat any Feedback you provide to us as
non-confidential and non-proprietary. You agree that you will
not submit to us any information or ideas that you consider to
be confidential or proprietary.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Age Requirements.`}</strong>{` By using the Services, you affirm that you are
18 years of age or older and are fully able and competent to enter
into the terms, conditions, obligations, affirmations,
representations, and warranties set forth in the Agreement, and to
abide by and comply with the Agreement. Our Services are not
directed to anyone under age 18, and anyone under age 18 may not use
the Services.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Objectionable Material`}</strong>{`. You acknowledge that, in using the
Services and accessing the Content, you may encounter material that
you deem to be disturbing, offensive or objectionable. You agree to
use the Services at your sole risk and that we will have no
liability to you for material that may be disturbing, objectionable
or offensive to you.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Disclaimers.`}</strong></p>
        <p parentName="li">{`11.1. TO THE MAXIMUM EXTENT PERMITTED BY LAW, AND SUBJECT TO CLAUSE
12, THE SERVICES ARE PROVIDED ON AN "AS-IS" AND "AS AVAILABLE"
BASIS, AND FAINTLINES, INC. (AND OUR SERVICE PROVIDERS)
EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY
KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL
WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR
NON-INFRINGEMENT. TO THE MAXIMUM EXTENT PERMITTED BY LAW, AND
SUBJECT TO CLAUSE 12, WE (AND OUR SERVICE PROVIDERS) MAKE NO
WARRANTY THAT THE SERVICES WILL MEET YOUR REQUIREMENTS, WILL BE
AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE
BASIS, OR WILL BE CURRENT, ACCURATE, RELIABLE, FREE OF VIRUSES
OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE. IF APPLICABLE
LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE SERVICES, ALL
SUCH WARRANTIES ARE LIMITED IN DURATION TO 6 MONTHS FROM THE
DATE OF FIRST USE.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Rights and remedies for consumers`}</strong></p>
        <p parentName="li">{`12.1. We will provide you with the Services that are described to you
on our website and that meet all the requirements imposed by
law. The Services will be as described, fit for purpose and of
satisfactory quality.`}</p>
        <p parentName="li">{`12.2. If the Services are faulty, you are entitled to a repair or a
replacement.`}</p>
        <p parentName="li">{`12.3. In relation to our paid Services including (but not limited to)
in-app purchases ("`}<strong parentName="p">{`Paid Services`}</strong>{`"), if there is a fault
which can't be fixed, or if it hasn't been fixed within a
reasonable time and without significant inconvenience, you can
get some or all of your money back.`}</p>
        <p parentName="li">{`12.4. If you can show a fault with the Services which has damaged your
device and we haven't used reasonable care and skill, you may be
entitled to a repair or compensation.`}</p>
        <p parentName="li">{`12.5. Subject to clause 12.6, you have a legal right to change your
mind about our Paid Services and receive a refund of what you
paid for it.`}</p>
        <p parentName="li">{`12.6. If you change your mind about a purchase you must let us know no
later than 14 days after the day we have accepted your order by
contacting `}<a parentName="p" {...{
            "href": "mailto:support@faintlines.com"
          }}>{`support@faintlines.com`}</a>{`, although you can't change
your mind about an order for Paid Services:`}</p>
        <Indent mdxType="Indent">12.6.1. after you have started to download these; and/or</Indent>
        <Indent mdxType="Indent">12.6.2. once these have been completed.</Indent>
        <p parentName="li">{`12.7. If we accept your request for a refund, we will refund you as
soon as possible and within 14 days of you telling us you've
changed your mind. We will refund you by the method you used for
payment. We do not charge a fee for the refund.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Limitation on Liability.`}</strong></p>
        <p parentName="li">{`13.1. WE EXPRESSLY EXCLUDE LIABILITY FOR ANY LOSS OR DAMAGE CAUSED BY
US OR OUR EMPLOYEES OR AGENTS IN CIRCUMSTANCES WHERE (A) THERE
IS NO BREACH OF A LEGAL DUTY OF CARE OWED TO YOU BY US OR BY ANY
OF OUR EMPLOYEES OR AGENTS; (B) SUCH LOSS OR DAMAGE IS NOT A
REASONABLY FORESEEABLE RESULT OF ANY SUCH BREACH; OR (C) SUCH
LOSS OR DAMAGE RELATES TO A BUSINESS. BY USING THE SERVICES
OFFERED BY US, YOU AGREE TO WAIVE ANY LEGAL DUTY OF CARE OWED TO
YOU BY US, TO THE MAXIMUM EXTENT ALLOWED BY LAW. IN ADDITION, TO
THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER WE NOR ANY OF OUR
EMPLOYEES, AGENTS, OR AFFILIATES SHALL BE LIABLE TO YOU OR ANY
THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY,
INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING
TO THIS AGREEMENT OR YOUR USE OF, OR INABILITY TO USE, THE
SERVICES. ACCESS TO, AND USE OF, THE SERVICES IS AT YOUR OWN
DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY
DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA
RESULTING FROM SUCH USE.`}</p>
        <p parentName="li">{`13.2. TO THE MAXIMUM EXTENT PERMITTED BY LAW, OUR TOTAL LIABILITY,
WHETHER ARISING IN CONTRACT, TORT, STRICT LIABILITY OR
OTHERWISE, INCLUDING LIABILITY FOR ANY LOSSES, COSTS, EXPENSES
OR DAMAGES THAT YOU SUFFER IN CONNECTION WITH THE SERVICES OR
THIS AGREEMENT SHALL NOT EXCEED (IN AGGREGATE) USD $100
NOTWITHSTANDING THE FOREGOING, NOTHING IN THESE TERMS SHALL
EXCLUDE OR LIMIT OUR LIABILITY FOR ANY LOSS OR DAMAGE, TO THE
EXTENT THAT SUCH LIABILITY CANNOT UNDER ANY APPLICABLE LAW BE
EXCLUDED OR LIMITED.`}</p>
        <p parentName="li">{`13.3. NOTHING IN THESE TERMS SHALL LIMIT OR EXCLUDE OUR LIABILITY FOR:`}</p>
        <Indent mdxType="Indent">
    13.3.1. DEATH OR PERSONAL INJURY CAUSED BY OUR NEGLIGENCE, OR THE
    NEGLIGENCE OF OUR EMPLOYEES, AGENTS OR SUBCONTRACTORS (AS APPLICABLE);
        </Indent>
        <Indent mdxType="Indent">13.3.2. FRAUD OR FRAUDULENT MISREPRESENTATION;</Indent>
        <Indent mdxType="Indent">
    13.3.3. BREACH OF THE TERMS IMPLIED BY SECTION 12 OF THE U.K. SALE OF
    GOODS ACT 1979 OR SECTION 2 OF THE U.K. SUPPLY OF GOODS AND SERVICES ACT
    1982, IF APPLICABLE; OR
        </Indent>
        <Indent mdxType="Indent">
    13.3.4. ANY OTHER MATTER IN RESPECT OF WHICH IT WOULD BE UNLAWFUL FOR US
    TO EXCLUDE OR RESTRICT LIABILITY.
        </Indent>
        <p parentName="li">{`13.4. NOTHING IN THESE TERMS AFFECTS YOUR STATUTORY RIGHTS AS A
CONSUMER.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Term and Termination.`}</strong>{` This Agreement will remain in full force
and effect while you use the Services. We may suspend or terminate
your rights to use the Services (including your Account, if any) at
any time for any reason at our sole discretion, including for any
use of the Services in violation of this Agreement. If you are an
individual consumer in the UK or EEA and you are using a paid
service, we may terminate the Services where reasonably required and
will provide you with reasonable advance notice of this, where
practicable. Upon termination of your rights under this Agreement,
your Account and right to access and use the Services will terminate
immediately. You understand that any termination of your Account may
involve permanent deletion of your User Content associated with your
Account. FaintLines, Inc. will not have any liability whatsoever to
you for any termination of your rights under this Agreement,
including for termination of your Account or deletion of your User
Content. Even after your rights under this Agreement are terminated,
the following provisions of this Agreement will remain in effect:
Sections 4 through 7, Section 8.1, Section 8.7, Section 8.9, and
Sections 12 through 18, inclusive.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`General.`}</strong></p>
        <p parentName="li">{`15.1. `}<em parentName="p">{`Changes`}</em>{`. This Agreement is subject to revision, and if we make
any substantial changes, we will notify you by sending you an
e-mail to the last e-mail address you provided to us (if any),
and/or by prominently posting notice of the changes on the
Services. If you are an individual consumer in the UK or EEA and
you are using a paid Service, such changes will only be made
where reasonably required. If you have provided us with an
e-mail address, you are responsible for ensuring that it is your
most current e-mail address. In the event that the last e-mail
address that you have provided us is not valid, or for any
reason is not capable of delivering to you the notice described
above, our dispatch of the e-mail containing such notice will
nonetheless constitute effective notice of the changes described
in the notice. Any changes to this Agreement will be effective
upon the earlier of seven (7) calendar days following our
dispatch of an e-mail notice to you (if applicable) or seven (7)
calendar days following our posting of notice of the changes on
any of the Services. These changes will be effective immediately
for new users of the Services. Continued use of the Services
following notice of such changes shall indicate your
acknowledgment of such changes and agreement to be bound by the
terms and conditions of such changes.`}</p>
        <p parentName="li">{`15.2. `}<em parentName="p">{`Export`}</em>{`. The Services may be subject to U.S. export control
laws and may be subject to export or import regulations in other
countries. You agree not to export, reexport, or transfer,
directly or indirectly, any U.S. technical data acquired from
FaintLines, Inc., or any products utilizing such data, in
violation of the United States export laws or regulations.`}</p>
        <p parentName="li">{`15.3. `}<em parentName="p">{`Complaints`}</em>{`. If you are dissatisfied with the Services or any
aspect of them, please contact us via: `}<a parentName="p" {...{
            "href": "mailto:support@faintlines.com."
          }}>{`support@faintlines.com.`}</a>{`
If you are unhappy with the outcome of your complaint, please
write to FaintLines, Inc. C/O ERB Financial Group International,
168 Main Street, PO Box 606, Goshen, New York 10924, USA, above
providing us with the following information: your name,
username, email address, the full details of your complaint, our
previous response to your complaint and why you disagree with
the outcome. Please mark the letter for the attention of the
“`}<em parentName="p">{`Customer Support Complaints Team`}</em>{`”. In addition, if you are a
resident of the state of California, USA, you may report
complaints to the Complaint Assistance Unit of the Division of
Consumer Services of the California Department of Consumer
Affairs in writing at 1625 North Market Blvd., Suite N 112,
Sacramento, California 95834 or by telephone at (800) 952-5210.`}</p>
        <p parentName="li">{`15.4. `}<em parentName="p">{`Electronic Communications`}</em>{`. By using the Services, you agree
that all terms and conditions, agreements, notices, disclosures,
and other communications that we provide to you electronically
satisfy any legal requirement that such communications be in a
hardcopy writing. The foregoing does not affect your
non-waivable rights. We will only contact you via text message
or phone calls if you provide us with your phone number and
authorize us to communicate with you in connection with the
Services.`}</p>
        <p parentName="li">{`15.5. `}<em parentName="p">{`Force Majeure`}</em>{`. Neither party will be responsible to the other
for any delay in performing under this Agreement which results
from civil disturbance, undeclared or declared war or other
hostilities, acts of terrorism, acts of anarchy, labor strikes
or interruptions, earthquakes or other acts of Nature or acts of
God, governmental orders, diseases, pandemics, or any cause
beyond the reasonable control of such party.`}</p>
        <p parentName="li">{`15.6. `}<em parentName="p">{`Governing Law`}</em>{`; `}<em parentName="p">{`Jurisdiction and Venue`}</em>{`.`}</p>
        <Indent mdxType="Indent">
    15.6.1. If you are an individual consumer in the UK or EEA, then this
    Agreement is governed by English law, you can bring claims against us in
    the courts of the country you live in, and we can claim against you in
    the courts of the country you live in.
        </Indent>
        <Indent mdxType="Indent">
    15.6.2. If you are not an individual consumer in the UK. or EEA, then
    this Agreement is governed by the laws of the State of New York, United
    States of America, and you irrevocably agree to submit all disputes
    arising out of or in connection with this Agreement according to the
    Dispute Resolution terms of Section 17 below.
        </Indent>
        <p parentName="li">{`15.7. `}<em parentName="p">{`Entire Agreement`}</em>{`. The Agreement constitutes the entire and
exclusive understanding and agreement between FaintLines, Inc.
and you regarding the Services and Content, and the Agreement
supersedes and replaces any and all prior oral or written
understandings or agreements between FaintLines, Inc. and you
regarding the Services and Content.`}</p>
        <p parentName="li">{`15.8. `}<em parentName="p">{`Waiver`}</em>{`. FaintLines, Inc.'s failure to enforce any right or
provision of the Agreement will not be considered a waiver of
such right or provision. The waiver of any such right or
provision will be effective only if in writing and signed by a
duly authorized representative of FaintLines, Inc. Except as
expressly set forth in the Agreement, the exercise by either
party of any of its remedies under the Agreement will be without
prejudice to its other remedies under the Agreement or
otherwise.`}</p>
        <p parentName="li">{`15.9. `}<em parentName="p">{`Severability`}</em>{`. If any provision of this Agreement is, for any
reason, held to be invalid or unenforceable, the other
provisions of this Agreement will be unimpaired and the invalid
or unenforceable provision will be deemed modified so that it is
valid and enforceable to the maximum extent permitted by law.`}</p>
        <p parentName="li">{`15.10 `}<em parentName="p">{`Section Titles`}</em>{`. The section titles in this Agreement are for
convenience only and have no legal or contractual effect. The
word "including" means "including without limitation".`}</p>
        <p parentName="li">{`15.11 `}<em parentName="p">{`Relationship of Parties`}</em>{`. Your relationship to FaintLines, Inc.
is that of an independent contractor, and neither party is an
agent or partner of the other.`}</p>
        <p parentName="li">{`15.12 `}<em parentName="p">{`Third Party Rights.`}</em>{` This Agreement is between you and us, and
does not create any right in any third party beneficiaries. In
addition, this Agreement does not give rise to any rights under
the U.K. Contracts (Rights of Third Parties) Act 1999 to enforce
any term of this Agreement.`}</p>
        <p parentName="li">{`15.13 `}<em parentName="p">{`Assignment and Delegation`}</em>{`. This Agreement, and your rights and
obligations herein, may not be assigned, subcontracted,
delegated, or otherwise transferred by you without FaintLines,
Inc.'s prior written consent, and any attempted assignment,
subcontract, delegation, or transfer in violation of the
foregoing will be null and void. We may freely assign this
Agreement. The terms and conditions set forth in this Agreement
shall be binding upon assignees.`}</p>
        <p parentName="li">{`15.14 `}<em parentName="p">{`Copyright/Trademark Information`}</em>{`. Copyright © 2024 FaintLines,
Inc., Inc. All rights reserved. All trademarks, logos and
service marks ("`}<strong parentName="p">{`Marks`}</strong>{`") displayed in connection with the
Services are our property or the property of other third
parties. You are not permitted to use these Marks without our
prior written consent or the consent of such third party which
may own the Marks.`}</p>
        <p parentName="li">{`15.15 `}<em parentName="p">{`Contact Information`}</em>{`. If you have additional questions or
comments of any kind, please let us know by sending your
comments or requests to us at: `}<a parentName="p" {...{
            "href": "mailto:feedback@faintlines.com"
          }}>{`feedback@faintlines.com`}</a>{`.`}</p>
      </li>
    </ol>
    <p><strong parentName="p"><em parentName="strong">{` Additional terms for United States residents and Services: If you are a resident of the United States or using the Services in the United States, then the following additional terms apply to you and your use of the Services: `}</em></strong></p>
    <ol {...{
      "start": 16
    }}>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Copyright Complaints`}</strong>{`.`}</p>
        <p parentName="li">{`16.1. FaintLines, Inc. respects the intellectual property rights of
others. We will respond to allegations of copyright infringement
in accordance with the Digital Millennium Copyright Act (the
"DMCA"), the text of which may be found on the U.S. Copyright
Office website at
`}<a parentName="p" {...{
            "href": "https://www.copyright.gov/legislation/dmca.pdf"
          }}>{`https://www.copyright.gov/legislation/dmca.pdf`}</a>{`.`}</p>
        <p parentName="li">{`16.2. If you are a copyright owner, or are authorized to act on behalf
of one, and believe copyright infringement is taking place on or
through the Service, you may report the alleged infringement to
us by completing a DMCA Notice of Alleged Infringement (`}<strong parentName="p">{`“DMCA
Notice`}</strong>{`” and delivering it to FaintLines, Inc.'s Designated
Agent identified below.`}</p>
        <Indent mdxType="Indent">16.2.1. The DMCA Notice must include:</Indent>
        <Indent amount={2} mdxType="Indent">16.2.1.1. your physical or electronic signature;</Indent>
        <Indent amount={2} mdxType="Indent">
    16.2.1.2. identification of the copyrighted work(s) that you claim to
    have been infringed;
        </Indent>
        <Indent amount={2} mdxType="Indent">
    16.2.1.3. identification of the material on the Services that you claim
    is infringing and that you request us to remove;
        </Indent>
        <Indent amount={2} mdxType="Indent">
    16.2.1.4. sufficient information to permit us to locate such material;
        </Indent>
        <Indent amount={2} mdxType="Indent">
    16.2.1.5. your address, telephone number, and e-mail address;
        </Indent>
        <Indent amount={2} mdxType="Indent">
    16.2.1.6. a statement that you have a good faith belief that use of the
    objectionable material is not authorized by the copyright owner, its
    agent, or under the law; and
        </Indent>
        <Indent amount={2} mdxType="Indent">
    16.2.1.7. a statement that the information in the notification is
    accurate, and under penalty of perjury, that you are either the owner of
    the copyright that has allegedly been infringed or that you are
    authorized to act on behalf of the copyright owner.
        </Indent>
        <Indent mdxType="Indent">
    16.2.2. Our Designated Agent is, and all DMCA Notices must be sent to:
    Gilad Raichstain at copyright@faintlines.com, or by certified mail and
    marked "Copyright Infringement", Attn: DMCA Agent, FaintLines, Inc. C/O
    ERB Financial Group International, 168 Main Street, PO Box 606, Goshen,
    New York 10924, USA.
        </Indent>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Dispute Resolution`}</strong>{`. Please read this Section 17 ("`}<strong parentName="p">{`Arbitration
Agreement`}</strong>{`") carefully. It is part of your contract with
FaintLines, Inc. and affects your rights. It contains procedures for
MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER.`}</p>
        <p parentName="li">{`17.1. `}<em parentName="p">{`Applicability of Arbitration Agreement`}</em>{`. All claims and
disputes (excluding claims for injunctive or other equitable
relief as set forth below) in connection with the Agreement or
the use of any product or Services provided by FaintLines, Inc.
that cannot be resolved informally or in small claims court
shall be resolved by binding arbitration on an individual basis
under the terms of this Arbitration Agreement. Unless otherwise
agreed to, all arbitration proceedings shall be held in English.`}</p>
        <p parentName="li">{`17.2. `}<em parentName="p">{`Notice Requirement and Informal Dispute Resolution`}</em>{`. Before
either party may seek arbitration, the party must first send to
the other party a written Notice of Dispute ("`}<strong parentName="p">{`Notice`}</strong>{`")
describing the nature and basis of the claim or dispute, and the
requested relief. Notices to FaintLines, Inc. must be sent to:
FaintLines, Inc. C/O ERB Financial Group International, 168 Main
Street, PO Box 606, Goshen, New York 10924, USA. After the
Notice is received, you and FaintLines, Inc. may attempt to
resolve the claim or dispute informally. If you and FaintLines,
Inc. do not resolve the claim or dispute within thirty (30) days
after the Notice is received, either party may begin an
arbitration proceeding. The amount of any settlement offer made
by any party may not be disclosed to the arbitrator until after
the arbitrator has determined the amount of the award, if any,
to which either party is entitled.`}</p>
        <p parentName="li">{`17.3. `}<em parentName="p">{`Arbitration Rules`}</em>{`.`}</p>
        <Indent mdxType="Indent">
    17.3.1. Arbitration shall be initiated through the American Arbitration
    Association ("<b>AAA</b>"), an established alternative dispute
    resolution provider ("<b>ADR Provider</b>") that offers arbitration as
    set forth in this section. If AAA is not available to arbitrate, the
    parties shall agree to select an alternative ADR Provider.
        </Indent>
        <Indent mdxType="Indent">
    17.3.2. The rules of the ADR Provider shall govern all aspects of the
    arbitration, including but not limited to the method of initiating
    and/or demanding arbitration, except to the extent such rules are in
    conflict with the Agreement. The Commercial Arbitration Rules and the
    Supplementary Procedures for Consumer-Related Disputes (the "
    <b>AAA Rules</b>") governing the dispute are available at
    https://www.adr.org/Rules or by calling the AAA at 1-800-778-7879.
        </Indent>
        <Indent mdxType="Indent">
    17.3.3. The arbitration shall be conducted by a single, neutral
    arbitrator. The single arbitrator will be either a retired judge or an
    attorney licensed to practice law and will be selected by the parties
    from the AAA's roster of arbitrators. If the parties are unable to agree
    upon an arbitrator within fourteen (14) days of delivery of the Notice,
    then the AAA will appoint the arbitrator in accordance with the AAA
    Rules.
        </Indent>
        <Indent mdxType="Indent">
    17.3.4. Any claims or disputes where the total amount of the award
    sought is less than Ten Thousand U.S. Dollars (US $10,000.00) may be
    resolved through binding non-appearance-based arbitration, at the option
    of the party seeking relief. For claims or disputes where the total
    amount of the award sought is Ten Thousand U.S. Dollars (US $10,000.00)
    or more, the right to a hearing will be determined by the AAA Rules.
        </Indent>
        <Indent mdxType="Indent">
    17.3.5. Any hearing will be held in a location within 100 miles of your
    residence, unless you reside outside of the United States, and unless
    the parties agree otherwise. If you reside outside of the U.S., the
    arbitrator shall give the parties reasonable notice of the date, time
    and place of any oral hearings.
        </Indent>
        <Indent mdxType="Indent">
    17.3.6. Any judgment on the award rendered by the arbitrator may be
    entered in any court of competent jurisdiction. If the arbitrator grants
    you an award that is greater than the last settlement offer that
    FaintLines, Inc. made to you prior to the initiation of arbitration,
    FaintLines, Inc. will pay you the greater of the award or $2,500.00.
        </Indent>
        <Indent mdxType="Indent">
    17.3.7. Each party shall bear its own costs (including attorney's fees)
    and disbursements arising out of the arbitration and shall pay an equal
    share of the fees and costs of the ADR Provider.
        </Indent>
        <p parentName="li">{`17.4. `}<em parentName="p">{`Additional Rules for Non-Appearance Based Arbitration`}</em>{`. If
non-appearance based arbitration is elected, the arbitration
shall be conducted by telephone, online and/or based solely on
written submissions; the specific manner shall be chosen by the
party initiating the arbitration. The arbitration shall not
involve any personal appearance by the parties or witnesses
unless otherwise agreed by the parties.`}</p>
        <p parentName="li">{`17.5. `}<em parentName="p">{`Time Limits`}</em>{`. If you or FaintLines, Inc. pursue arbitration,
the arbitration action must be initiated and/or demanded within
the statute of limitations (i.e., the legal deadline for filing
a claim) and within any deadline imposed under the AAA Rules for
the pertinent claim.`}</p>
        <p parentName="li">{`17.6. `}<em parentName="p">{`Authority of Arbitrator`}</em>{`. If arbitration is initiated, the
arbitrator will decide the rights and liabilities, if any, of
you and FaintLines, Inc., and the dispute will not be
consolidated with any other matters or joined with any other
cases or parties. The arbitrator shall have the authority to
grant motions dispositive of all or part of any claim. The
arbitrator shall have the authority to award monetary damages,
and to grant any non-monetary remedy or relief available to an
individual under applicable law, the AAA Rules, and this
Agreement. The arbitrator shall issue a written award and
statement of decision describing the essential findings and
conclusions on which the award is based, including the
calculation of any damages awarded. The arbitrator has the same
authority to award relief on an individual basis that a judge in
a court of law would have. The award of the arbitrator is final
and binding upon you and FaintLines, Inc.`}</p>
        <p parentName="li">{`17.7. `}<em parentName="p">{`Waiver of Jury Trial`}</em>{`. THE PARTIES HEREBY WAIVE THEIR
CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A
TRIAL IN FRONT OF A JUDGE OR A JURY, instead electing that all
claims and disputes shall be resolved by arbitration under this
Arbitration Agreement. Arbitration procedures are typically more
limited, more efficient and less costly than rules applicable in
a court and are subject to very limited review by a court. In
the event any litigation should arise between you and
FaintLines, Inc. in any state or federal court in a suit to
vacate or enforce an arbitration award or otherwise, YOU AND
FAINTLINES, INC. WAIVE ALL RIGHTS TO A JURY TRIAL, instead
electing that the dispute be resolved by a judge.`}</p>
        <p parentName="li">{`17.8. `}<em parentName="p">{`Waiver of Class or Consolidated Actions`}</em>{`. ALL CLAIMS AND
DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE
ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A
CLASS BASIS, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT
BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF
ANY OTHER CUSTOMER OR USER.`}</p>
        <p parentName="li">{`17.9. `}<em parentName="p">{`Confidentiality.`}</em>{` All aspects of the arbitration proceeding,
including but not limited to the award of the arbitrator and
compliance therewith, shall be strictly confidential. The
parties agree to maintain confidentiality unless otherwise
required by law. This paragraph shall not prevent a party from
submitting to a court of law any information necessary to
enforce this Agreement, to enforce an arbitration award, or to
seek injunctive or equitable relief.`}</p>
        <p parentName="li">{`17.10. `}<em parentName="p">{`Severability.`}</em>{` If any part or parts of this Arbitration
Agreement are found under the law to be invalid or unenforceable
by a court of competent jurisdiction, then such specific part or
parts shall be of no force and effect and shall be severed and
the remainder of the Agreement shall continue in full force and
effect.`}</p>
        <p parentName="li">{`17.11. `}<em parentName="p">{`Right to Waive`}</em>{`. Any or all of the rights and limitations set
forth in this Arbitration Agreement may be waived by the party
against whom the claim is asserted. Such waiver shall not waive
or affect any other portion of this Arbitration Agreement.`}</p>
        <p parentName="li">{`17.12. `}<em parentName="p">{`Survival of Agreement.`}</em>{` This Arbitration Agreement will survive
the termination of your relationship with FaintLines, Inc.`}</p>
        <p parentName="li">{`17.13. `}<em parentName="p">{`Emergency Equitable Relief.`}</em>{` Notwithstanding the foregoing,
either party may seek emergency equitable relief before a state
or federal court in order to maintain the status quo pending
arbitration. A request for interim measures shall not be deemed
a waiver of any other rights or obligations under this
Arbitration Agreement.`}</p>
        <p parentName="li">{`17.14. `}<em parentName="p">{`Claims Not Subject to Arbitration`}</em>{`. Notwithstanding the
foregoing, claims of defamation, violation of the Computer Fraud
and Abuse Act, and infringement or misappropriation of the other
party's patent, copyright, trademark or trade secrets shall not
be subject to this Arbitration Agreement.`}</p>
        <p parentName="li">{`17.15. `}<em parentName="p">{`Courts.`}</em>{` In any circumstances where the foregoing Arbitration
Agreement permits the parties to litigate in court, the parties
hereby agree to submit to the personal jurisdiction of the
courts located within New York, USA, for such purpose.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Indemnification`}</strong>{`. Unless prohibited or limited by law, you agree
to indemnify and hold FaintLines, Inc. and our affiliates, officers,
directors, agents, successors and assigns from and against any and
all claims, demands, suits, proceedings, liabilities, judgments,
losses, damages, expenses, and costs (including, but not limited to,
reasonable attorneys' fees) assessed or incurred by such parties,
directly or indirectly, with respect to or arising out of: (a) your
use of the Services, (b) your violation of this Agreement, (c) your
violation of applicable laws or regulations or (d) your User
Content. FaintLines, Inc. reserves the right, at your expense, to
assume the exclusive defense and control of any matter for which you
are required to indemnify us, and you agree to cooperate with our
defense of these claims. You agree not to settle any matter without
the prior written consent of FaintLines, Inc. We will use reasonable
efforts to notify you of any such claim, action or proceeding upon
becoming aware of it.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Release`}</strong>{`. To the maximum extent permitted by law, you hereby
release and forever discharge FaintLines, Inc. and our affiliates,
officers, directors, agents, successors and assigns from, and hereby
waive and relinquish, each and every past, present and future
dispute, claim, controversy, demand, right, obligation, liability,
action and cause of action of every kind and nature (including
personal injuries, death, and property damage), that has arisen or
arises directly or indirectly out of, or that relates directly or
indirectly to, the Services or your participation in any Adventure,
including any interactions with, or act or omission of, other
Service users, actors, the general public, or any third-party
websites or resources. IF YOU ARE A RESIDENT OF THE STATE OF
CALIFORNIA, USA, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542
IN CONNECTION WITH THE FOREGOING, WHICH STATES: "A GENERAL RELEASE
DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR
SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE
RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED
HIS OR HER SETTLEMENT WITH THE DEBTOR."`}</p>
      </li>
    </ol>
    <p>{`FaintLines, Inc.`}</p>
    <p>{`C/O ERB Financial Group International`}<br />{`
168 Main Street, PO Box 606, Goshen`}<br />{`
New York 10924`}<br />{`
USA`}</p>
    <img src={imagePN} alt="" />
    <p><a parentName="p" {...{
        "href": "mailto:support@faintlines.com"
      }}>{`support@faintlines.com`}</a></p>
    <p><strong parentName="p">{`Last modified`}</strong>{`: February 25, 2024`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      